import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const ItemAuction = ({
  img,
  lotsCount,
  auctionNumber,
  dateFrom,
  dateTo,
  name,
}) => {
  return (
    window.socket_io_customer === 'luisina_veiga' ?
      <div className='position-relative item-auction item-auction-lv'>
        <Link style={{ textDecoration: 'none' }} to={`subastas/${auctionNumber}`}>
          <img alt='' src={img} />
          <div className='content-box-lv'>
            <div className='content'>
              <div className='line'>
                <span>Desde</span> {dateFrom}<span>, hasta</span> {dateTo}
              </div>
              <h3>
                {name} #{auctionNumber}
              </h3>
              <span className='lots'>
                <b>{lotsCount}</b> lotes
              </span>
            </div>

            <button>Ir a la subasta</button>
          </div>
        </Link>
      </div>
      :
      <div className='position-relative item-auction '>
        <div className='image-cover'>
          <div className='content-box'>
            <span className='lots'>
              <b>{lotsCount}</b> lotes
            </span>
            <div className='content'>
              <h3>
                {name} #{auctionNumber}
              </h3>
              <div className='line'>
                <span>inicio</span> {dateFrom}
              </div>
              <div className='line'>
                <span>cierre</span> {dateTo}
              </div>
              <Link to={`subastas/${auctionNumber}`}>
                <button>Ir a la subasta</button>
              </Link>
            </div>
          </div>
        </div>
        <img alt='' src={img} />
      </div>
  );
};

export default ItemAuction;
