import React from 'react';
import { Link } from 'react-router-dom';
import { Constants } from '../../../Constants';
import { useAccount } from '../../../store/account';
import { connect } from 'react-redux';
import CompleteData from '../../modals/CompleteData';

const PrimaryMenu = (props) => {
  const { isLuisina, sessionProps } = props;
  const accountStore = useAccount();

  return (
    <>
      {
        window.socket_io_customer !== 'remates_de_maria' && sessionProps.account !== null && (!sessionProps.account.phone || sessionProps.account.phone === null || sessionProps.account.phone === '') ? (
          <CompleteData props={props} />
        ) : null
      }
      <div className='primary-menu'>
        {
          isLuisina ?
            <div className='box-items container' style={{
              fontSize: '15px',
            }}>
              <div className='end-items'>
                <Link to='/' className='link'>
                  <div className='item'>Inicio</div>
                </Link>
                {
                  accountStore?.config?.include_page_conditions ? (
                    <Link to='/terminos-condiciones' className='link'>
                      <div className='item'>Término & condiciones</div>
                    </Link>
                  ) : null
                }
                {
                  accountStore?.config?.include_page_buy && (
                    <Link to='/comprar' className='link'>
                      <div className='item'>Comprar</div>
                    </Link>
                  )
                }
                {
                  accountStore?.config?.include_page_sell && (
                    <Link to='/vender' className='link'>
                      <div className='item'>Vender</div>
                    </Link>
                  )
                }
                <Link to='/ayuda' className='link'>
                  <div className='item'>F.A.Q</div>
                </Link>
                <Link to='/contacto' className='link'>
                  <div className='item'>Contacto</div>
                </Link>
              </div>
            </div>
            :
            <div className='box-items container' style={{ padding: `${window.socket_io_customer === 'remates_cardozo' ? '5px 0px' : '15px 0px'}` }}>
              <div className='start-items'>
                <div className='item'>
                  <Link to='/' className='link'>
                    <img
                      src={accountStore.config?.logo_web ? Constants.BASE_URL + accountStore.config.logo_web : ''}
                      alt='logo'
                      className='logo'
                      style={{ height: `${window.socket_io_customer === 'remates_cardozo' ? '90px' : '40px'}` }}
                    />
                  </Link>
                </div>
              </div>
              <div className='end-items'>
                <Link to='/' className='link'>
                  <div className='item'>Inicio</div>
                </Link>
                {
                  accountStore?.config?.include_page_conditions ? (
                    <Link to='/terminos-condiciones' className='link'>
                      <div className='item'>Término & condiciones</div>
                    </Link>
                  ) : null
                }
                {
                  accountStore?.config?.include_page_buy && (
                    <Link to='/comprar' className='link'>
                      <div className='item'>Comprar</div>
                    </Link>
                  )
                }
                {
                  accountStore?.config?.include_page_sell && (
                    <Link to='/vender' className='link'>
                      <div className='item'>Vender</div>
                    </Link>
                  )
                }
                <Link to='/ayuda' className='link'>
                  <div className='item'>F.A.Q</div>
                </Link>
                <Link to='/contacto' className='link'>
                  <div className='item'>Contacto</div>
                </Link>
              </div>
            </div>
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
});

export default connect(mapStateToProps)(PrimaryMenu);
