import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const ItemSmallAuction = ({ img, auctionNumber, dateFinished, name }) => {
  return (
    <Link style={{ textDecoration: 'none' }} to={`subastas/${auctionNumber}`}>
      <div className='position-relative item-small-auction mt-4'>
        <>
          <div className='image-cover'>
            <div className='content-box'>
              <div className='content'>
                <h3>
                  {name} #{auctionNumber}
                </h3>
                <div className='line'>
                  <span>finalizada</span> {dateFinished}
                </div>
              </div>
            </div>
          </div>
          <img className='img-small' alt='' src={img} />
        </>
      </div>
    </Link >
  );
};

export default ItemSmallAuction;
